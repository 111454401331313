import React, { useState, useEffect } from 'react';
import { Container, Row, Button, Modal, Form } from 'react-bootstrap';
import getScriptString from '../../functions/getScriptString';

export default function ScriptureToMemorizeModal({
  showModal,
  handleCloseModal = f => f,
  books,
  scriptureToMemorize,
  handleClickUpdate = f => f,
  handleClickDelete = f => f,
}) {
  const [scriptString, setScriptString] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    if (showModal === true) {
      init();
    }
  }, [showModal]);

  const init = async () => {
    setScriptString("");
    setText("");
    if (scriptureToMemorize) {
      const scripture = scriptureToMemorize.scripture;
      const script = scripture.script;
      setScriptString(getScriptString(script, books));
      setText(scripture.text);
    }
  }

  const handleChangeText = (event) => {
    setText(event.target.value);
  }

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Body className="show-grid">
        <Container>
          {scriptureToMemorize &&
            <>
              <Row>
                {scriptString}
              </Row>
              <Form.Group>
                <Form.Control as="textarea" aria-describedby="description" placeholder="聖句の本文を入力..." rows={3} value={text} onChange={(e) => handleChangeText(e)} />
              </Form.Group>
            </>
          }
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClickDelete}>
          削除
        </Button>
        <Button variant="success" onClick={() => handleClickUpdate(text)}>
          更新
        </Button>
      </Modal.Footer>
    </Modal>
  )
}